import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import Img from 'gatsby-image';

const CarouselWrapper = styled.section`
  .hero {
    position: relative;
    max-height: 80vh;

    .content {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      z-index: 100;
      text-align: center;
    }
  }

  .slick-dots {
    bottom: 20px;
    li button:before {
      color: #017bff;
      font-size: 12px;
    }
  }
`;

export default function Carousel({ heroBanner }) {
  const settings = {
    arrows: false,
    dots: true,
    // fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <CarouselWrapper>
      <Slider {...settings}>
        {heroBanner.map(({ id, photo, title, subtitle }) => (
          <div key={id} className="hero">
            {/* <div className="content">
              <h1 className="text-xl md:text-5xl text-black tracking-wider">
                {title}
              </h1>
              <p className="text-base md:text-xl">{subtitle}</p>
            </div> */}
            <Img
              style={{ maxHeight: '80vh' }}
              imgStyle={{ objectFit: 'contain' }}
              fluid={photo.fluid}
            />
          </div>
        ))}
      </Slider>
    </CarouselWrapper>
  );
}
