import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
// import ModalContext from '../store/modalContext';
import SEO from '../components/SEO';
import AboutUs from '../sections/AboutUs';
import Affiliate from '../sections/Affiliate';
import Approach from '../sections/Approach';
import Carousel from '../sections/Carousel';
import CompanyProfile from '../sections/CompanyProfile';
import Footer from '../sections/Footer';
import Philosophy from '../sections/Philosophy';
import Services from '../sections/Services';

const indexQuery = graphql`
  {
    datoCmsHomePage {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBanner {
        id
        title
        subtitle
        photo {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      aboutUs {
        title
        description
        image {
          url
        }
      }
      companyProfile {
        title
        description
        images {
          url
        }
      }
      service {
        id
        title
        description
        image {
          url
        }
      }
      designPhilosophy {
        id
        title
        image {
          url
        }
      }
      approach {
        id
        title
        image {
          url
        }
      }
      partner {
        title
        description
        images {
          url
        }
      }
      clientLogos {
        url
      }
      clientSectionTitle
      philosophySectionTitle
      approachSectionTitle
      serviceSectionTitle
    }

    projects: allDatoCmsPortfolioPost(
      limit: 6
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      nodes {
        id
        description
        title
        slug
        category {
          categoryName
        }
        imageGallery {
          url
        }
      }
    }
  }
`;

const ClientSideRecentProjects = React.lazy(() =>
  import('../sections/RecentProjects')
);

const ClientSideClientele = React.lazy(() => import('../sections/Clientele'));

export default function IndexPage() {
  const isSSR = typeof window === 'undefined';
  const data = useStaticQuery(indexQuery);
  const { nodes: projects } = data.projects;
  // const { openModal } = useContext(ModalContext);
  const {
    approachSectionTitle,
    philosophySectionTitle,
    clientSectionTitle,
    serviceSectionTitle,

    seoMetaTags,
    heroBanner,
    aboutUs,
    companyProfile,
    service,
    designPhilosophy,
    approach,
    partner,
    clientLogos,
  } = data.datoCmsHomePage;

  return (
    <div className="pt-20">
      <SEO meta={seoMetaTags} />
      <Carousel heroBanner={heroBanner} />
      <AboutUs aboutUs={aboutUs[0]} />
      <CompanyProfile companyProfile={companyProfile[0]} />
      <Philosophy
        philosophies={designPhilosophy}
        title={philosophySectionTitle}
      />
      <Services services={service} title={serviceSectionTitle} />
      <Approach approaches={approach} title={approachSectionTitle} />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideRecentProjects projects={projects} />
        </React.Suspense>
      )}
      <Affiliate partner={partner[0]} />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideClientele logos={clientLogos} title={clientSectionTitle} />
        </React.Suspense>
      )}
      <Footer />
    </div>
  );
}
