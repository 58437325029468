import React from 'react';
import Title from '../components/Title';

export default function Philosphy({ title, philosophies }) {
  return (
    <div className="bg-orange-light py-12 md:py-20">
      <div className="container">
        <Title text={title} />
        <section className="mt-4 md:mt-12 grid grid-flow-row md:grid-cols-4 lg:grid-cols-8 gap-6">
          {philosophies.map(({ id, title, image }, index) => {
            const [titleFirstWord, ...titleRest] = title.split(' ');
            return (
              <div
                key={id}
                className="col-span-2 grid grid-rows-1 items-center"
                data-sal="slide-up"
              >
                {/* TITLE */}
                <h4 className="uppercase tracking-wider mt-5 mb-2 text-center">
                  <span className={index % 3 ? '' : ''}>
                    {titleFirstWord}&nbsp;
                  </span>
                  {titleRest.join(' ')}
                </h4>
                {/* IMAGE */}
                <div
                  className="h-48 bg-red-200 from-red-400 via-gray-500 to-pink-500 shadow-md"
                  style={{
                    backgroundImage: `url(${image.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}
