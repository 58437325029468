import React from 'react';

export default function Title({ text, underline = true, alignLeft = false }) {
  return (
    <>
      <h2
        className={`text-3xl font-bold tracking-wide uppercase ${
          alignLeft ? 'text-left' : 'text-center'
        }`}
      >
        {text}
      </h2>
      {underline && (
        <div
          className={`mt-1 h-1 w-16 bg-orange-theme ${
            alignLeft ? 'ml-2' : 'mx-auto'
          }`}
        ></div>
      )}
    </>
  );
}
