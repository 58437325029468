import React from 'react';

export default function SquareMasonry({ images, classNames, extraKey }) {
  return (
    <div className={`grid grid-cols-2 grid-rows-10 gap-3 ${classNames}`}>
      {images &&
        images.map((img, index) => (
          <div
            key={`img-${extraKey ?? ''}-${index}`}
            style={{
              backgroundImage: `url(${img.url})`,
            }}
            className="shadow-md bg-center bg-cover"
            data-sal="slide-up"
          ></div>
        ))}
    </div>
  );
}
