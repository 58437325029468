import React from 'react';
import Title from '../components/Title';

export default function Approach({ title, approaches }) {
  return (
    <div className="bg-orange-light py-12 md:py-20 px-3">
      <div className="container">
        <Title text={title} />
        <section className="mt-4 md:mt-12 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-6">
          {approaches.map(({ id, title, image }) => {
            return (
              <div
                key={id}
                className="grid grid grid-rows-1 items-center"
                data-sal="slide-up"
              >
                {/* TITLE */}
                <h4 className="uppercase tracking-wider mt-5 mb-2 text-center">
                  {title}
                </h4>
                {/* IMAGE */}
                <div
                  style={{
                    height: '250px',
                    backgroundImage: `url(${image.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}
