import React from 'react';
import SquareMasonry from '../components/SquareMasonry';
import Title from '../components/Title';
import { formatBranding } from '../utils/helpers';

export default function CompanyProfile({ companyProfile }) {
  const { title, description, images } = companyProfile;

  return (
    <div className="container py-12 md:py-24 px-3 leading-relaxed">
      <section className="grid grid-cols-3 mb-10">
        <div className="col-span-3 md:col-span-1 order-last md:order-none h-48 md:h-auto pt-8">
          <SquareMasonry classNames="h-full" images={images} />
        </div>
        <div className="col-span-3 md:col-span-2 md:pl-12">
          <Title text={title} />

          <div className="text-center md:text-justify flex flex-wrap">
            <div
              className="mt-6 text-justify font-normal richText"
              data-sal="slide-up"
              dangerouslySetInnerHTML={{
                __html: formatBranding(description),
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
