import React from 'react';
import Title from '../components/Title';
import { formatBranding } from '../utils/helpers';

export default function AboutUs({ aboutUs }) {
  const { title, description, image } = aboutUs;
  return (
    <>
      <div className="bg-orange-light -mt-2 px-3" id="about-us">
        <div className="container py-12 md:py-24 md:px-5 lg:px-20 leading-relaxed">
          <section className="grid grid-cols-3">
            <div className="col-span-3 md:col-span-2 px-2 md:pr-12 text-center md:text-left">
              <Title text={title} />
              <div
                className="mt-6 text-justify font-normal richText"
                data-sal="slide-up"
                dangerouslySetInnerHTML={{
                  __html: formatBranding(description),
                }}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <div
                className="h-full bg-red-200 from-yellow-400 via-red-500 to-pink-500 bg-cover bg-center shadow-md"
                style={{ backgroundImage: `url(${image.url})` }}
              />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
