import React from 'react';
import Title from '../components/Title';
import { formatBranding } from '../utils/helpers';

export default function Affiliate({ partner }) {
  const { title, description, images } = partner;
  return (
    <div className="bg-orange-light">
      <div className="container leading-relaxed px-3 py-12 md:py-24">
        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 md:col-span-3">
            <Title text={title} />
            <p
              className="tracking-wider mt-10"
              dangerouslySetInnerHTML={{ __html: formatBranding(description) }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 col-span-5 md:col-span-2">
            {images.map(({ url }) => (
              <div
                className="col-span-1 flex justify-center align-center"
                key={url}
              >
                <img
                  className="object-contain"
                  src={url}
                  alt={url}
                  style={{ maxHeight: '160px' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
