import React from 'react';
import Title from '../components/Title';

export default function Services({ title, services }) {
  return (
    <div className="py-8">
      <div className="container py-12 md:py-20 text-center md:text-left">
        <Title text={title} />
        <section className="mt-16 grid grid-flow-row md:grid-cols-4 lg:grid-cols-6 gap-10">
          {services.map(({ id, title, description, image }) => {
            const [titleFirstWord, ...titleRest] = title.split(' ');
            return (
              <div key={id} className="col-span-2">
                {/* IMAGE */}
                <div
                  data-sal="slide-up"
                  className="h-48"
                  style={{
                    backgroundImage: `url(${image.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                {/* TITLE */}
                <h4 className="uppercase tracking-widest mt-5 mb-2">
                  <span
                    className={
                      // index % 3 ? 'text-orange-theme' : 'text-blue-theme'
                      'text-current'
                    }
                  >
                    {titleFirstWord}&nbsp;
                  </span>
                  {titleRest.join(' ')}
                </h4>
                {/* CONTENT */}
                {description.split('\n').map((p, i) => (
                  <p className="text-base mt-2" key={`${id}-${i}`}>
                    {p}
                  </p>
                ))}
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}
