const formatBranding = text => {
  return text.replace(
    /decorzo space/gi,
    `
      <span class="text-orange-theme font-bold">DECORZO</span>
      <span class="text-blue-theme font-bold">SPACE</span>
    `
  );
};

export { formatBranding };
