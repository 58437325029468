import React from 'react';
import { Link } from 'gatsby';

import FbIcon from '../svgs/socials-fill/facebook.inline.svg';
import InstaIcon from '../svgs/socials-fill/instagram.inline.svg';
import WaIcon from '../svgs/socials-fill/whatsapp.inline.svg';
import { ContactConsumer } from '../containers/Layout';

export default function Footer() {
  return (
    <ContactConsumer>
      {({
        address,
        phone,
        whatsappNumber,
        facebookUrl,
        instagramUrl,
        email,
      }) => {
        return (
          <footer className="pt-8 pb-3 px-5 bg-orange-soft">
            <div className="container">
              <div className="flex flex-wrap text-base text-center md:text-left">
                <div className="w-full md:w-1/3 mb-5">
                  <h4 className="mb-1">Quick Links</h4>
                  <Link
                    className="inline-block hover:text-white"
                    to="/services"
                  >
                    Services
                  </Link>
                  <br />
                  <Link
                    className="inline-block hover:text-white"
                    to="/portfolio"
                  >
                    Our Projects
                  </Link>
                  <br />
                  <Link className="inline-block hover:text-white" to="/contact">
                    Contact Us
                  </Link>
                </div>

                <div className="w-full md:w-1/3 mb-5">
                  <div className="mb-5">
                    <h4>Office Contact</h4>
                    <a className="hover:text-white" href={`tel:${phone}`}>
                      {phone}
                    </a>
                  </div>
                  <div>
                    <h4>Email Address</h4>
                    <a className="hover:text-white" href={`mailto:${email}`}>
                      {email}
                    </a>
                  </div>
                </div>

                <div className="w-full md:w-1/3 mb-5">
                  <div className="mb-5">
                    <h4>Office Address</h4>
                    {address}
                  </div>

                  <div>
                    <a
                      className="mr-3 social-link-invert"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://wa.me/${whatsappNumber}`}
                    >
                      <WaIcon className="w-6 h-6" />
                    </a>

                    <a
                      className="mr-3 social-link-invert"
                      target="_blank"
                      rel="noreferrer"
                      href={facebookUrl}
                    >
                      <FbIcon className="w-6 h-6" />
                    </a>
                    <a
                      className="mr-3 social-link-invert"
                      target="_blank"
                      rel="noreferrer"
                      href={instagramUrl}
                    >
                      <InstaIcon className="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </div>

              <p className="text-center text-xs mt-4">
                &copy; {new Date().getFullYear()} DECORZO SPACE All Rights
                Reserved
              </p>
            </div>
          </footer>
        );
      }}
    </ContactConsumer>
  );
}
